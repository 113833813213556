<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataUserBarcode"
            @page-count="pageCount = $event"
            :page.sync="page"
            :loading="loading"
            hide-default-footer
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" align="right">
                    <v-btn
                      v-if="modify"
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="addUser"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah User
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                small
                class="font-weight-bold error--text"
                v-if="item.is_activated === 0"
              >
                Disabled
              </v-chip>
              <v-chip small class="font-weight-bold primary--text" v-else>
                Active
              </v-chip>
            </template>

            <template v-if="modify" v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="editUser(item.id)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="next"
              total-visible="5"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <add-data
      :addDataDialog="addDataDialog"
      :roleList="roleList"
      :gudangList="gudangList"
      @closeAddDataDialog="closeAddDataDialog"
      @reloadDataAdd="reloadDataAdd"
    />

    <edit-data
      :olahDataDialog="olahDataDialog"
      :olahData="olahData"
      :roleList="roleList"
      :gudangList="gudangList"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadDataEdit="reloadDataEdit"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="450">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import AddData from "./addData.vue";
import EditData from "./editData.vue";

export default {
  components: { AddData, EditData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Nama",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "User Name",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "username",
      },
      {
        text: "Role",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "roles_name",
      },
      {
        text: "Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "gudang_name",
      },
      {
        text: "Status",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "status",
      },
      {
        text: "Created By",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "created_by",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataUserBarcode: [],
    roleList: [],
    gudangList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dataLength: "",
    olahDataDialog: false,
    olahData: {},
    addDataDialog: false,
    progress: false,
    popDialog: false,
    popTitle: "",
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),
    getData() {
      this.loading = true;
      axios
        .get(
          "v1/admin/user_gudang/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page
        )
        .then((response) => {
          this.dataUserBarcode = response.data.data;
          this.loading = false;
          this.getDataLength();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.log(error.response);
          }
        });
    },
    getDataLength() {
      axios.get("v1/admin/user_gudang/list?limit=100").then((response) => {
        this.dataLength = response.data.data.length;
        var count = response.data.data.length / this.itemsPerPage;
        if (count < 1) {
          this.pageCount = 0;
        } else {
          this.pageCount = Math.ceil(
            response.data.data.length / this.itemsPerPage
          );
        }
      });
    },
    getRoleList() {
      axios.get("v1/admin/role/list?limit=100").then((response) => {
        response.data.data.forEach((element) => {
          if (element.is_user_web === 0) {
            this.roleList.push(element);
          }
        });
      });
    },
    getGudangList() {
      axios.get("v1/user/gudang/list").then((response) => {
        this.gudangList = response.data.data;
      });
    },
    getDataById(value) {
      axios.get("v1/admin/user_gudang?id=" + value).then((response) => {
        const data = response.data.data;
        if (data.is_activated === 0) {
          data.is_activated = 2;
        }
        this.olahData = data;
        this.progress = false;
        this.olahDataDialog = true;
      });
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    addUser() {
      this.addDataDialog = true;
    },
    editUser(value) {
      this.progress = true;
      this.getDataById(value);
    },
    reloadDataAdd() {
      this.popTitle = "Data User Barcode berhasil ditambahkan";
      this.popDialog = true;
    },
    reloadDataEdit() {
      this.popTitle = "Data User Barcode berhasil diupdate";
      this.popDialog = true;
    },
    closeAddDataDialog() {
      this.dataUserBarcode = [];
      this.getData();
      this.addDataDialog = false;
    },
    closeOlahDataDialog() {
      this.dataUserBarcode = [];
      this.getData();
      this.olahDataDialog = false;
      this.olahData = {};
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
  },

  mounted() {
    this.getData();
    this.getRoleList();
    this.getGudangList();
  },
};
</script>