<template>
  <div>
    <v-dialog v-model="addDataDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          Tambah User Barcode
        </v-card-title>

        <v-divider class="mx-5"></v-divider>

        <v-card-text class="pb-0 mt-3">
          <v-form ref="form" v-model="valid">
            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Nama
            </v-card-title>
            <v-text-field
              v-model="data.name"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              User Name
            </v-card-title>
            <v-text-field
              v-model="data.username"
              outlined
              dense
              :rules="[rules.required, rules.minReq]"
              autocomplete="off"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Password
            </v-card-title>
            <v-text-field
              v-model="data.passw"
              outlined
              dense
              :rules="[rules.required, rules.minReq]"
              autocomplete="off"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Role
            </v-card-title>
            <v-select
              v-model="data.role_id"
              dense
              outlined
              :items="roleList"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
            >
            </v-select>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Gudang
            </v-card-title>
            <v-select
              v-model="data.gudang_id"
              dense
              outlined
              :items="gudangList"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
            >
            </v-select>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeAddData"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="checkUsername"
            :disabled="!valid"
            :loading="loading"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="500">
      <v-card color="red" rounded>
        <v-card-title class="text-h6 white--text">
          Data Gagal Ditambahkan !!
        </v-card-title>
        <v-card-title class="text-caption white--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    addDataDialog: Boolean,
    roleList: Array,
    gudangList: Array,
  },
  data: () => ({
    valid: false,
    loading: false,
    popDialog: false,
    popTitle: "",
    data: {
      name: "",
      username: "",
      passw: "",
      role_id: "",
      gudang_id: "",
    },
    rules: {
      required: (value) => !!value || "Tidak boleh kosong !!",
      minReq: (value) => value.length >= 6 || "Min 6 karakter",
    },
  }),
  methods: {
    checkUsername() {
      this.data.username = this.data.username.toLowerCase();
      this.loading = true;
      axios
        .get("v1/admin/user_gudang/username?username=" + this.data.username)
        .then(() => {
          this.popTitle = "Username sudah digunakan";
          this.popDialog = true;
        })
        .catch(() => {
          console.clear();
          this.save();
        });
    },
    save() {
      this.data.name = this.data.name.replace(/\b\w/g, (c) => c.toUpperCase());
      const dataSend = JSON.stringify(this.data);
      axios
        .post("v1/admin/user_gudang", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit("reloadDataAdd");
          this.closeAddData();
        })
        .catch((error) => {
          if (error.response) {
            this.popTitle = error.response.data.errMessage;
            this.popDialog = true;
          }
        });
    },
    resetForm() {
      this.data = {
        name: "",
        email: "",
        passw: "",
        role_id: "",
        gudang_id: "",
      };
    },
    closeAddData() {
      this.resetForm();
      this.$refs.form.resetValidation();
      this.$emit("closeAddDataDialog");
    },
    closePopDialog() {
      this.loading = false;
      this.popDialog = false;
    },
  },
};
</script>