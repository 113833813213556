<template>
  <div>
    <sidebar :id="id" :menu="menu" :submenu="submenu" />
    <v-container fluid>
      <template v-if="display">
        <LoadData :modify="modify" />
      </template>
      <template v-else>
        <v-alert dense outlined type="error"> Not Authorized ! </v-alert>
      </template>
    </v-container>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import LoadData from "../../components/MasterData/userBarcode/loadData.vue";
import { mapGetters } from "vuex";

export default {
  name: "Data_User_Barcode",

  components: { Sidebar, LoadData },

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Data User Barcode",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "2",
    menu: "Master Data",
    submenu: " -> User Barcode",
    display: true,
    modify: true,
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },

  created() {
    if (this.user.dataPermission.includes("barcode.display")) {
      this.display = true;
    } else {
      this.display = false;
    }
    if (this.user.dataPermission.includes("barcode.modify")) {
      this.modify = true;
    } else {
      this.modify = false;
    }
  },
};
</script>